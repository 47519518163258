<template>
	<div class="activity">
		<w-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="banner"><img :src="require('@/assets/img/text205.jpg')" /></div>
			<div class="activity-container">
				<w-tab :active="active" :data="tabs" @click="onTab" :search="true" @click-search="onClickSearch">
				</w-tab>
				<div class="activity-list">
					<activity-item v-for="vo in activityList" :activity="vo" @click="onClickItem"></activity-item>
				</div>
			</div>
		</w-list>
		<!-- <w-tabbar></w-tabbar> -->
	</div>
</template>

<script>
import ActivityApi from '@/api/activity';
import WList from '@/components/List';
import WTab from '@/components/Tab';
import WTabbar from '@/components/Tabbar';
import ActivityItem from '@/components/ActivityItem';
import District from '@/vendor/district';
import scrollMiXin from '@/mixins/scroll';

const scroll = scrollMiXin(['.list-view']);

export default {
	name: 'Activity',
	mixins: [scroll],
	inject: ['isCity', 'isDistrict'],
	data() {
		return {
			tabs: [{ id: 1, title: '全部活动' }, { id: 2, title: '可报名活动' }],
			loading: true,
			finished: false,
			isEmpty: false,
			active: 0,
			paramsPage: 1,
			paramsLimit: 10,
			activityList: [],
			districtId: ""
		};
	},
	created() {

		this.$store.commit('keep/setKeep', 'Activity');

		/**
		 * districtId为空，则调用全部的（市级）
		 */
		if (this.isDistrict()) {
			this.districtId = District.getDistrictId()
		}

		this.onLoad();
	},
	methods: {
		onLoad() {
			this.loading = true;

			ActivityApi.getList({
				page: this.paramsPage,
				limit: this.paramsLimit,
				type: this.tabs[this.active].id,
				district_id: this.districtId
			}).then(result => {
				this.paramsPage++;
				this.activityList = this.activityList.concat(result.data.list);

				this.loading = false;

				if (this.activityList.length <= 0) {
					this.isEmpty = true;
				}

				if (this.activityList.length >= result.data.total) {
					this.finished = true;
				}
			}).catch(error => {
				console.log(error);
			});
		},
		onTab(key) {
			this.active = key;
			this.paramsPage = 1;
			this.isEmpty = false;
			this.finished = false;
			this.activityList = []
			this.onLoad();
		},
		onClickItem(itemData) {
			this.$router.push({
				name: 'ActivityDetail',
				params: {
					activityId: itemData.id
				}
			});
		},
		onClickSearch() {
			this.$router.push({
				name: 'ActivitySearch'
			});
		}
	},
	components: {
		WTab,
		WList,
		WTabbar,
		ActivityItem
	}
};
</script>

<style lang="less" scoped>
.activity {
	min-height: 100vh;
	padding-bottom: 10px;
	box-sizing: border-box;
	// min-height: calc(100vh - 50px);
	// height: calc(100vh - 50px);

	.activity-scroll {
		position: relative;
	}

	.banner {
		width: 100%;
		height: 200px;

		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
		}
	}

	.activity-container {
		position: relative;
		margin-top: -15px;
		background-color: #f2f2f2;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		box-sizing: border-box;
		padding: 15px 10px 0;
		z-index: 10;

		.activity-list {
			margin-top: 10px;

			/deep/.activity-item {
				border-radius: 8px;
				margin-bottom: 10px;
			}

			/deep/.activity-item:last-child {
				margin-bottom: 0;
			}
		}
	}
}
</style>
